
import { LazyLoadComponent } from "react-lazy-load-image-component";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";
import routes from "./helper/routes";
import { useEffect, useMemo, useState } from "react";
import { ToastContainer } from "react-toastify";
import {
  getItemFromLocalStorage,
  setItemInLocalStorage,
} from "./helper/helper";
import { getRequest } from "./helper/api";
import Preloader from "./constants/Preloader";
import Forbidden from "./views/Forbidden";
const getRoutes = (routes, user) => {
  return routes.flatMap((prop, key) => {
    const userPermissions = user?.permissions || [];

    if (user) {
      // Routes requiring access permissions
      if (prop?.accessRequired && prop?.layout === "PRIVATE") {
        if (userPermissions.includes(prop?.name)) {
          // User has access, render route
          if (prop?.subMenu) {
            return prop.subMenu.map((subProp, subKey) => (
              <Route
                exact
                path={subProp.path}
                element={
                  <LazyLoadComponent>
                    <subProp.component />
                  </LazyLoadComponent>
                }
                key={`${key}-${subKey}`}
              />
            ));
          } else {
            return (
              <Route
                exact
                path={prop.path}
                element={
                  <LazyLoadComponent>
                    <prop.component />
                  </LazyLoadComponent>
                }
                key={key}
              />
            );
          }
        } else {
          // User does not have access, render 403
          return (
            <Route exact path={prop.path} element={<Forbidden />} key={key} />
          );
        }
      }

      // Routes not requiring access permissions
      if (!prop?.accessRequired) {
        if (prop?.subMenu) {
          return prop.subMenu.map((subProp, subKey) => (
            <Route
              exact
              path={subProp.path}
              element={
                <LazyLoadComponent>
                  <subProp.component />
                </LazyLoadComponent>
              }
              key={`${key}-${subKey}`}
            />
          ));
        } else {
          return (
            <Route
              exact
              path={prop.path}
              element={
                <LazyLoadComponent>
                  <prop.component />
                </LazyLoadComponent>
              }
              key={key}
            />
          );
        }
      }
    }

    // Routes for non-authenticated users (public routes)
    if (!user) {
      if (prop?.subMenu) {
        return prop.subMenu.map((subProp, subKey) => (
          <Route
            exact
            path={subProp.path}
            element={
              <LazyLoadComponent>
                <subProp.component />
              </LazyLoadComponent>
            }
            key={`${key}-${subKey}`}
          />
        ));
      } else {
        return (
          <Route
            exact
            path={prop.path}
            element={
              <LazyLoadComponent>
                <prop.component />
              </LazyLoadComponent>
            }
            key={key}
          />
        );
      }
    }

    // Default fallback (e.g., render 404 or similar)
    return null;
  });
};

function App() {
  const [user, setUser] = useState(null); // Maintain the user state
  const [isLoading, setIsLoading] = useState(true); // Maintain the loading state

  const fetchUser = useMemo(() => {
    return async () => {
      try {
        const storedUser = JSON.parse(getItemFromLocalStorage("USER"));
        const token = getItemFromLocalStorage("TOKEN");
        if (storedUser && storedUser?._id) {
          // Fetch profile based on userId (from query param or local storage)
          const { result, error } = await getRequest(
            `/api/secure/v2/${storedUser?.roleName.replace(" ", "")}/profile`,
            token
          );
          if (result?.status === 200 || result?.status === 201) {
            const { user } = result?.data;
            setUser(user);
            setItemInLocalStorage("USER", JSON.stringify(user));
          }
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error("Error while fetching user:", error);
        setUser(null);
      } finally {
        setIsLoading(false);
      }
    };
  }, []);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  if (isLoading) {
    return <Preloader />;
  }

  return (
    <Router>
      <Routes>
        {getRoutes(routes, user)}
        {/* <Route
          path="*"
          element={<h1 className="text-center">Error 404 | Page Not Found</h1>}
        /> */}
        <Route
          path="*"
          element={
            user ? (
              <Navigate to="/" replace /> // Redirect authenticated users to dashboard
            ) : (
              <Navigate to="/user/login" replace /> // Redirect unauthenticated users to login
            )
          }
        />
      </Routes>
      <ToastContainer />
    </Router>
  );
}

export default App;
