import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, replace } from "formik";
import * as Yup from "yup";
import { Button, Col, Container, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { toast } from "react-toastify";
import { postRequestForm } from "../../../helper/api";
import Layout from "../../../layouts/Layout";
import { Images } from "../../../constants/Images";
import Input from "../../../constants/Input";
import BackButton from "../../../constants/BackButton";

const SuperAdminForgetPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Validation Schema for Email
  const forgetPasswordSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  // Form Submission Handler
  const forgetPasswordHandler = async (values) => {
    try {
      setLoading(true);
      const res = await postRequestForm(
        "/api/auth/v2/SuperAdmin/forget-password",
        "",
        {
          email: values.email?.toLowerCase(),
        }
      );

      if (res?.result?.status === 200) {
        const user = res.result.data?.user;
        toast.success("Email sent successfully. Please check your inbox.", {
          position: "top-center",
          autoClose: 5000,
        });

        // Redirect to OTP page with the correct user ID
        navigate(`/superadmin/otp`, {
          state: { _id: user._id, pathToGo: "superadmin/reset-password" },
          replace: true,
        });
      } else {
        throw new Error(
          res?.error?.response?.data?.message || "Request failed."
        );
      }
    } catch (err) {
      toast.error(err.message || "An error occurred.", {
        position: "top-center",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <section
        id="forgetPassword"
        style={{
          backgroundImage: `url(${Images.background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container>
          <BackButton
            content={"Back"}
            icon={Images.backArrow}
            onClick={() => navigate("/superadmin/login", { replace: true })}
          />
          <Row className="justify-content-center">
            <Col lg={4} md={8} xs={12}>
              {/* Logo */}
              <div className="image-container text-center mb-5">
                <LazyLoadImage src={Images.logo} className="w-50" alt="Logo" />
              </div>

              {/* Forget Password Form */}
              <Formik
                initialValues={{ email: "" }}
                validationSchema={forgetPasswordSchema}
                onSubmit={forgetPasswordHandler}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="text-start">
                      <h1 className="newPassword">FORGOT PASSWORD</h1>
                      <p className="text mb-3">
                        Enter your email for the verification process. We will
                        send a 4-digit code to your email.
                      </p>
                    </div>

                    {/* Email Input */}
                    <Input
                      formType="email"
                      formPlaceholder="EMAIL"
                      formHandleChange={handleChange}
                      formHandleBlur={handleBlur}
                      formName="email"
                      formValue={values.email}
                      icon={Images.mail}
                      size="md"
                      iconColor="white"
                    />
                    {errors.email && touched.email && (
                      <div className="text-danger">{errors.email}</div>
                    )}

                    {/* Submit Button */}
                    <Button
                      type="submit"
                      className="btn-default w-100 mt-4"
                      disabled={loading}
                    >
                      {loading ? (
                        <span className="spinner-border spinner-border-sm"></span>
                      ) : (
                        "CONTINUE"
                      )}
                    </Button>
                  </form>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default SuperAdminForgetPassword;
