import React, { useEffect, useState } from "react";
import { Form, Button, InputGroup, Container } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Images } from "../../../constants/Images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";
import Layout from "../../../layouts/Layout";
import BackButton from "../../../constants/BackButton";
import { getRequest } from "../../../helper/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";

const CreateTournament = () => {
  const navigate = useNavigate();
  const [tournamentName, setTournamentName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [tournamentType, setTournamentType] = useState("");
  const [tournamentId, setTournamentId] = useState("");

  const tournamentTypeOptions = [
    { value: "FC", label: "FC" },
    { value: "LIST A", label: "LIST A" },
    { value: "WT20", label: "WT20" },
    {
      value: "WT20I",
      label: "WT20I",
    },
    {
      value: "WODI",
      label: "WODI",
    },
    {
      value: "WLIST-A",
      label: "WLIST-A",
    },
    {
      value: "100",
      label: "100",
    },
    {
      value: "T20I",
      label: "T20I",
    },
    {
      value: "ODI",
      label: "ODI",
    },
    {
      value: "T20",
      label: "T20",
    },
    {
      value: "TEST",
      label: "TEST",
    },
    {
      value: "T10",
      label: "T10",
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!tournamentName || !tournamentType || !startDate || !endDate) {
      toast.error("Please fill all fields and select teams.", {
        position: "top-center",
      });
      return;
    }

    const tournamentData = {
      _id: tournamentId,
      tournamentName,
      tournamentType,
      startDate: startDate,
      endDate: endDate,
    };

    navigate("/team-selection", storeInLocalStorage(tournamentData));
  };

  const storeInLocalStorage = (data) => {
    localStorage.setItem("tournamentId", JSON.stringify(data._id));
    localStorage.setItem("tournamentName", JSON.stringify(data.tournamentName));
    localStorage.setItem("tournamentType", JSON.stringify(data.tournamentType));
    localStorage.setItem("startDate", JSON.stringify(data.startDate));
    localStorage.setItem("endDate", JSON.stringify(data.endDate));
  };

  useEffect(() => {
    setTournamentName(JSON.parse(localStorage.getItem("tournamentName")) || "");
    setTournamentType(JSON.parse(localStorage.getItem("tournamentType")) || "");
    setStartDate(JSON.parse(localStorage.getItem("startDate")) || "");
    setEndDate(JSON.parse(localStorage.getItem("endDate")) || "");
  }, []);

  return (
    <Layout>
      <section
        id="createTournament"
        style={{
          backgroundImage: `url(${Images.background2})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <BackButton
          icon={Images.backArrow}
          content="Back"
          onClick={() => {
            navigate("/dashboard");
            localStorage.removeItem("tournamentId");
            localStorage.removeItem("tournamentName");
            localStorage.removeItem("tournamentType");
            localStorage.removeItem("startDate");
            localStorage.removeItem("endDate");
            localStorage.removeItem("players");
          }}
        />

        <Container className="image-input-container">
          <div className="image-container text-center mt-4 mb-5">
            <LazyLoadImage src={Images.logo} width={100} alt="Logo" />
          </div>

          <Form onSubmit={handleSubmit}>
            {/* Tournament Name */}
            <InputGroup className="mb-3" size="lg">
              <InputGroup.Text>
                <LazyLoadImage src={Images.user} alt="icon" />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Tournament Name"
                onChange={(e) => setTournamentName(e.target.value)}
                value={tournamentName}
                required
              />
            </InputGroup>

            {/* Tournament Type */}
            <InputGroup className="mb-3" size="lg">
              <InputGroup.Text>
                <LazyLoadImage src={Images.tournamentType} alt="icon" />
              </InputGroup.Text>
              <Form.Select
                aria-label="Tournament Type"
                onChange={(e) => setTournamentType(e.target.value)}
                value={tournamentType}
                required
              >
                <option value="" disabled>
                  Select Tournament Type
                </option>
                {tournamentTypeOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </InputGroup>

            {/* Start Date */}
            <InputGroup className="mb-3" size="lg">
              <InputGroup.Text>
                <LazyLoadImage src={Images.calendar} alt="icon" />
              </InputGroup.Text>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="Select Start Date"
                className="form-control"
                dateFormat="dd-MM-yyyy"
                required
              />
            </InputGroup>

            {/* End Date */}
            <InputGroup className="mb-3" size="lg">
              <InputGroup.Text>
                <LazyLoadImage src={Images.calendar} alt="icon" />
              </InputGroup.Text>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                placeholderText="Select End Date"
                className="form-control"
                dateFormat="dd-MM-yyyy"
                required
              />
            </InputGroup>

            {/* Start Button */}
            <Button
              variant="primary"
              type="submit"
              className="btn-default w-100 d-flex align-items-center justify-content-center"
            >
              START
              <FontAwesomeIcon className="ms-4" icon={faCircleChevronRight} />
            </Button>
          </Form>
        </Container>
      </section>
    </Layout>
  );
};

export default CreateTournament;
