import React, { useState } from "react";
import { Form, Button, Container, Modal, Row, Col } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faRemove,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Images } from "../../constants/Images";
import BackButton from "../../constants/BackButton";
import Layout from "../../layouts/Layout";

function Previous() {
  // State to store teams and their selection status
  const [teams, setTeams] = useState([
    {
      teamName1: "England",
      teamFlag1:
        "https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_England.svg/1200px-Flag_of_England.svg.png",
      id: 1,
      teamName2: "India",
      teamFlag2:
        "https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png",
      date: "2022-01-01",
      time: "10:00 PM",
    },
    {
      teamName1: "Pakistan",
      teamFlag1:
        "https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png",
      id: 2,
      teamName2: "Australia",
      teamFlag2:
        "https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png",
      date: "2022-01-01",
      time: "10:00 PM",
    },
    {
      teamName1: "Africa",
      teamFlag1:
        "https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png",
      id: 3,
      teamName2: "New Zealand",
      teamFlag2:
        "https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png",
      date: "2022-01-01",
      time: "10:00 PM",
    },
  ]);

  return (
    
    <Layout>
    <section
      id="previous"
      style={{
        backgroundImage: `url(${Images.background2})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",                   
      }}
    >
      <BackButton icon={Images.backArrow} content="Back" />
      <div className="logo-container">
        <LazyLoadImage
          src={Images.logo}
          className="my-5"
          alt="logo"
          width={100}
        />
      </div>
      <Container className="list-container w-100">
        <h3 className="heading border-bottom-0">Recent Matches</h3>
        <div className="item-container">
          {teams.map((team) => (
            <Row key={team.id} className="align-items-center list-item">
              {/* Team 1 Icon and Name */}
              <Col xs={2} className="d-flex align-items-center">
                <LazyLoadImage
                  src={team.teamFlag1}
                  alt={team.teamName1}
                  width={30}
                  height={20}
                />
                <span className="ms-2 text-nowrap">
                  <p className="heading border-0">{team.teamName1}</p>
                </span>
              </Col>

              {/* VS Label */}
              <Col xs={1} className="text-center">
                <p className="heading border-0">VS</p>
              </Col>

              {/* Team 2 Icon and Name */}
              <Col xs={2} className="d-flex align-items-center">
                <LazyLoadImage
                  src={team.teamFlag2}
                  alt={team.teamName2}
                  width={30}
                  height={20}
                />
                <span className="ms-2 text-nowrap">
                  <p className="heading border-0">{team.teamName2}</p>
                </span>
              </Col>

              {/* Match Date */}
              <Col xs={2} className="text-center">
                {team.date}
              </Col>

              {/* Match Time */}
              <Col xs={2} className="text-center">
                {team.time}
              </Col>

              {/* Download Button */}
              <Col xs={3} className="justify-content-end item-buttons">
                <Button className="btn-default w-auto me-3">
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
                <Button className="btn-default w-auto">RESUME</Button>
              </Col>
            </Row>
          ))}
        </div>
      </Container>
    </section>
    </Layout>
  );
}

export default Previous;
