import { Col, Container, Row } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const EditTeamSkeleton = () => {
  return (
    <Container className="list-container w-75">
      {" "}
      <SkeletonTheme baseColor="#fff" highlightColor="#ddd">
        <Row>
          {/* Team Name Skeleton */}
          <Col lg={10} md={10} xs={10}>
            <span className="pb-2 heading d-flex align-items-center">
              <p className="mb-0 me-3">
                <Skeleton width={100} height={16} />
              </p>
              <Skeleton height={38} width="50%" />
            </span>
          </Col>

          {/* Image Upload Skeleton */}
          <Col lg={2} md={2} xs={2}>
            <div className="upload__image-wrapper mb-3">
              <Skeleton
                circle
                width={80}
                height={80}
                style={{ border: "1px solid rgba(231, 50, 147, 1)" }}
              />
            </div>
          </Col>

          {/* Player List Skeleton */}
          <Col lg={12} md={12} xs={12}>
            <div className="item-container mb-4">
              {Array(4)
                .fill()
                .map((_, index) => (
                  <div
                    className="list-item d-flex align-items-center"
                    key={index}
                  >
                    <span className="d-flex align-items-center">
                      <Skeleton
                        circle
                        width={50}
                        height={50}
                        className="me-3"
                      />
                      <Skeleton width={150} height={20} />
                    </span>
                    <Skeleton width={20} height={20} className="ms-auto" />
                  </div>
                ))}
            </div>
          </Col>
        </Row>

        {/* Add Player Buttons Skeleton */}
        <div className="d-flex justify-content-between mb-4">
          <Skeleton width="30%" height={40} />
          <Skeleton width="30%" height={40} />
        </div>

        {/* Submit Button Skeleton */}
        <div>
          <Skeleton width="100%" height={40} />
        </div>
      </SkeletonTheme>
    </Container>
  );
};

export default EditTeamSkeleton;
