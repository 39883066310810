import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";

const PairButtons = ({
  content1,
  content2,
  backgroundColor1,
  backgroundColor2,
  icon1,
  icon2,
  onClick1,
  onClick2,
  color1,
  color2,
  reversedIcon1,
  reversedIcon2,
}) => {
  return (
    <div className="button-group-pink-white">
      <Button
        variant="primary"
        className="button-pink-white me-4"
        style={{
          backgroundColor: backgroundColor1,
          color: color1,
        }}
        onClick={onClick1}
      >
        {icon1 && !reversedIcon1 && (
          <LazyLoadImage src={icon1} alt="icon" width={15} className="me-3" />
        )}
        <span>{content1}</span>
        {reversedIcon1 && (
          <LazyLoadImage src={icon1} alt="icon" width={15} className="ms-3" />
        )}
      </Button>
      {content2 && <Button
        variant="primary"
        className="button-pink-white"
        style={{
          backgroundColor: backgroundColor2,
          color: color2,
        }}
        onClick={onClick2}
      >
        {icon2 && !reversedIcon2 && (
          <LazyLoadImage src={icon2} alt="icon" width={15} className="me-3" />
        )}
        <span>{content2}</span>
        {reversedIcon2 && (
          <LazyLoadImage src={icon2} alt="icon" width={15} className="ms-3" />
        )}
      </Button>}
    </div>
  );
};

export default PairButtons;
