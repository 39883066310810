import React, { useEffect, useState } from "react";
import Layout from "../../layouts/Layout";
import { Button, Container } from "react-bootstrap";
import BackButton from "../../constants/BackButton";
import { Images } from "../../constants/Images";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronDown,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import PairButtons from "../../constants/PairButtons";
import TeamsModal from "../../constants/TeamsModal";
import { deleteRequest, getRequest } from "../../helper/api";
import Skeleton from "react-loading-skeleton";

const StartMatch = () => {
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null); // Selected tournament details
  const [teamDetails, setTeamDetails] = useState(null); // Team data for modal
  const [tableData, setTableData] = useState([]); // Tournaments
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(4);
  const [totalTournaments, setTotalTournaments] = useState(0);
  const [loading, setLoading] = useState(false);

  // Fetch all tournaments
  const getTournaments = async () => {
    try {
      setLoading(true);
      const { result, error } = await getRequest(
        `/api/secure/tournament/get-tournaments`
      );
      if (result?.status === 200) {
        const tournaments = result.data.tournaments.map((tournament) => ({
          id: tournament._id,
          name: tournament.tournamentName,
          startDate: tournament.startDate,
          endDate: tournament.endDate,
          teams: tournament.tournamentTeams,
          createdAt: tournament.createdAt,
          updatedAt: tournament.updatedAt,
        }));
        setTableData(tournaments);
        setTotalTournaments(tournaments.length);
        setLoading(false);
      } else {
        throw new Error(
          error?.response?.data?.message || "Failed to fetch tournaments"
        );
      }
    } catch (err) {
      console.error("Error fetching tournaments:", err.message);
    }
  };

  // Fetch team details by ID
  const getTeamDetails = async (teamId) => {
    try {
      const { result, error } = await getRequest(
        `/api/secure/team/get-team?_id=${teamId}`
      );
      if (result?.status === 200) {
        return result.data;
      } else {
        throw new Error(
          error?.response?.data?.message || "Failed to fetch team details"
        );
      }
    } catch (err) {
      console.error("Error fetching team details:", err.message);
      return null;
    }
  };

  // Fetch and open modal with team details
  const handleRowClick = async (row) => {
    setSelectedRowData(row);
    const teamDetails = await Promise.all(
      row.teams.map((teamId) => getTeamDetails(teamId))
    );
    setTeamDetails(teamDetails.filter((team) => team !== null)); // Filter out failed requests
    console.log(teamDetails);
    setModalShow(true);
  };

  useEffect(() => {
    getTournaments();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage) => {
    setPerPage(newPerPage);
  };

  const handleDelete = async (tournamentId) => {
    try {
      const { result, error } = await deleteRequest(
        `/api/secure/tournament/remove-tournament?_id=${tournamentId}`
      );
      if (result?.status === 200) {
        getTournaments();
      } else {
        throw new Error(
          error?.response?.data?.message || "Failed to delete tournament"
        );
      }
    } catch (err) {
      console.error("Error deleting tournament:", err.message);
    }
  };

  const columns = [
    {
      name: "Year",
      selector: (row) => moment(row.startDate).format("YYYY"),
      sortable: true,
      maxWidth: "90px",
      style: {
        color: "white",
        borderRight: "1px solid white",
        justifyContent: "center",
      },
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      minWidth: "250px",
      cell: (row) => (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span style={{ marginRight: "8px", color: "white" }}>{row.name}</span>
          <div>
            <FontAwesomeIcon
              icon={faTrash}
              style={{ color: "white", cursor: "pointer", marginRight: "8px" }}
              onClick={() => handleDelete(row.id)}
            />
            <FontAwesomeIcon
              icon={faCircleChevronDown}
              style={{ color: "white", cursor: "pointer" }}
              onClick={() => handleRowClick(row)}
            />
          </div>
        </div>
      ),
      style: {
        color: "white",
        borderRight: "1px solid white",
      },
    },
    {
      name: "Start Date",
      selector: (row) => moment(row.startDate).format("DD-MM-YYYY"),
      sortable: true,
      maxWidth: "130px",
      style: {
        color: "white",
        borderRight: "1px solid white",
        justifyContent: "center",
      },
    },
    {
      name: "End Date",
      selector: (row) => moment(row.endDate).format("DD-MM-YYYY"),
      sortable: true,
      maxWidth: "120px",
      style: { color: "white", justifyContent: "center" },
    },
  ];

  const SkeletonTableRows = () =>
    Array(4)
      .fill()
      .map((_, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 0",
            borderBottom: "1px solid white",
          }}
        >
          <span className="d-flex align-items-center">
            <Skeleton width={60} height={20} className="me-3" />
            <Skeleton width={250} height={20} />
          </span>
          <span className="d-flex align-items-center">
            <Skeleton width={100} height={20} className="me-3" />
            <Skeleton width={100} height={20} />
          </span>
        </div>
      ));

  return (
    <Layout>
      <section
        id="startMatch"
        style={{
          backgroundImage: `url(${Images.background2})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <BackButton
          icon={Images.backArrow}
          content="Back"
          onClick={() => navigate("/dashboard")}
        />
        <Container className="logo-table-container">
          <LazyLoadImage
            className="logo mt-3 mb-5"
            src={Images.logo}
            width={100}
            alt="Logo"
          />
          <div className="table-container">
            <p className="pb-2 heading">Tournaments</p>
            {loading ? (
              <SkeletonTableRows />
            ) : (
              <DataTable
                columns={columns}
                data={tableData.slice(
                  (currentPage - 1) * perPage,
                  currentPage * perPage
                )}
                pagination
                paginationServer
                paginationTotalRows={totalTournaments}
                paginationPerPage={perPage}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
              />
            )}
            <PairButtons
              content1="Back"
              onClick1={() => navigate("/dashboard")}
              icon1={Images.backArrowButton}
              backgroundColor1={"#fff"}
              color1={"#000"}
              content2="Add New"
              onClick2={() => navigate("/create-tournament")}
              icon2={Images.plus}
              backgroundColor2={"rgba(231, 50, 147, 1)"}
              color2={"#fff"}
            />
          </div>
        </Container>
      </section>

      {/* Modal for showing selected team data */}
      <TeamsModal
        show={modalShow}
        data={selectedRowData}
        teamDetails={teamDetails} // Pass fetched team details
        onHide={() => setModalShow(false)}
      />
    </Layout>
  );
};

export default StartMatch;
