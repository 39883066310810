import React from "react";
import {
  ButtonGroup,
  Container,
  Dropdown,
  DropdownButton,
  Navbar,
  Row,
  Col,
} from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Images } from "./Images";
import { Link, useNavigate } from "react-router-dom";
import { removeItemFromLocalStorage } from "../helper/helper";
import { uploadURL } from "../helper/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faUserNinja,
  faUserNurse,
} from "@fortawesome/free-solid-svg-icons";

const NavComponent = ({ user }) => {
  const navigate = useNavigate();
  console.log(user);

  return (
    <Navbar className="bg-body-transparent navbar-component">
      <Container>
        <Navbar.Brand>Welcome, {user?.lastName}</Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <div className="navbar-profile">
            <LazyLoadImage
              src={user?.profileImageUrl || Images.profile}
              height={40}
              width={40}
              alt="User"
              style={{
                borderRadius: "50%",
                backgroundColor: "#f0f0f0",
              }}
            />
            <DropdownButton
              as={ButtonGroup}
              id="dropdown-button-drop-end"
              drop="down"
              variant="transparent"
              className="dropdown-menu-end"
              style={{
                ":hover,:focus,:active": {
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  border: "none",
                },
              }}
              title=""
            >
              <Container>
                <Row className="g-0">
                  <Col xs={4}>
                    <LazyLoadImage
                      src={user?.profileImageUrl || Images.profile}
                      height={40}
                      width={40}
                      alt="User"
                      style={{
                        borderRadius: "50%",
                        backgroundColor: "#f0f0f0",
                      }}
                    />
                  </Col>
                  <Col xs={12}>
                    <div className="mt-2">
                      <h6>
                        <strong>{user?.firstName}</strong>
                      </h6>
                      <p className="mb-0" style={{ wordBreak: "break-all" }}>
                        {user?.email}
                      </p>
                    </div>
                  </Col>
                </Row>
                <Dropdown.Divider />
                <Dropdown.Item
                  eventKey="2"
                  onClick={() => navigate(`/profile-settings`)}
                >
                  <LazyLoadImage
                    src={Images.settings}
                    height={20}
                    width={20}
                    alt="Settings Icon"
                    className="me-2"
                  />
                  Profile Settings
                </Dropdown.Item>
                {/* <Dropdown.Item eventKey="3">
                  <LazyLoadImage
                    src={Images.help}
                    height={20}
                    width={20}
                    alt="Help Icon"
                    className="me-2"
                  />
                  <Link to="/user-management">User Management</Link>
                </Dropdown.Item> */}
                <Dropdown.Item
                  eventKey="4"
                  onClick={() => navigate(`/change-password`)}
                >
                  <LazyLoadImage
                    src={Images.upgrade}
                    height={20}
                    width={20}
                    alt="Upgrade Icon"
                    className="me-2"
                  />
                  Change Password
                </Dropdown.Item>
                {/* <Dropdown.Item eventKey="4">
                  <FontAwesomeIcon
                    icon={faUser}
                    width={20}
                    height={20}
                    alt="User Icon"
                    className="me-2"
                  />
                  <Link to="/dashboard/all-admin">Admins</Link>
                </Dropdown.Item>
                <Dropdown.Item eventKey="4">
                  <FontAwesomeIcon
                    icon={faUserNinja}
                    width={20}
                    height={20}
                    alt="User Icon"
                    className="me-2"
                  />
                  <Link to="/dashboard/all-super-admin">Super Admins</Link>
                </Dropdown.Item> */}
                <Dropdown.Divider />
                <Dropdown.Item
                  eventKey="5"
                  onClick={(e) => {
                    e.preventDefault();
                    removeItemFromLocalStorage("TOKEN");
                    removeItemFromLocalStorage("USER");

                    window.location.reload();
                  }}
                >
                  <LazyLoadImage
                    src={Images.signout}
                    height={20}
                    width={20}
                    alt="Signout Icon"
                    className="me-2"
                  />
                  Sign Out
                </Dropdown.Item>
              </Container>
            </DropdownButton>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavComponent;
