import { Col, Container, Row } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const EditPlayerSkeleton = () => {
  return (
    <Container className="list-container w-75">
      <SkeletonTheme baseColor="#fff" highlightColor="#ddd">
        <Row className="align-items-start">
          {/* Player Name Skeleton */}
          <Col lg={9} md={9} xs={9}>
            <div className="pb-3 heading border-0">
              <p className="mb-2">
                <Skeleton width={100} height={16} />
              </p>
              <Skeleton height={38} />
            </div>

            {/* Batting Style Skeleton */}
            <div className="pb-3 heading border-0">
              <p className="mb-2">
                <Skeleton width={120} height={16} />
              </p>
              <div className="d-flex">
                <Skeleton circle width={20} height={20} className="me-2" />
                <Skeleton width={50} height={16} className="me-5" />
                <Skeleton circle width={20} height={20} className="me-2" />
                <Skeleton width={50} height={16} />
              </div>
            </div>

            {/* Wicket Keeper Skeleton */}
            <div className="pb-3 heading border-0">
              <p className="mb-2">
                <Skeleton width={120} height={16} />
              </p>
              <div className="d-flex">
                <Skeleton circle width={20} height={20} className="me-2" />
                <Skeleton width={50} height={16} className="me-5" />
                <Skeleton circle width={20} height={20} className="me-2" />
                <Skeleton width={50} height={16} />
              </div>
            </div>
          </Col>

          {/* Player Image Skeleton */}
          <Col lg={3} md={3} xs={12} className="d-flex justify-content-center">
            <Skeleton
              circle
              width={120}
              height={120}
              style={{ border: "1px solid rgba(231, 50, 147, 1)" }}
            />
          </Col>

          {/* Bowling Style Skeleton */}
          <div className="heading border-0 w-100 mt-4">
            <p className="mb-0 pt-3">
              <Skeleton width={120} height={16} />
            </p>
            <Row className="justify-content-center">
              {/* Pace Options */}
              <Col xs={6}>
                <Skeleton height={38} />
              </Col>
              {/* Spin Options */}
              <Col xs={6}>
                <Skeleton height={38} />
              </Col>
            </Row>
          </div>

          {/* Submit Button Skeleton */}
          <div className="w-100 mt-4">
            <Skeleton height={38} width="100%" />
          </div>
        </Row>
      </SkeletonTheme>
    </Container>
  );
};

export default EditPlayerSkeleton;
