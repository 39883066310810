import { removeItemFromLocalStorage } from "./helper";

const axios = require("axios");
const qs = require("querystring");

let baseURL;

if (process.env.REACT_APP_MODE === "DEVELOPMENT") {
  baseURL = process.env.REACT_APP_API_URL_DEV;
} else if (process.env.REACT_APP_MODE === "PRODUCTION") {
  baseURL = process.env.REACT_APP_API_URL;
} else {
  baseURL = process.env.REACT_APP_API_URL_LOCAL;
}
export { baseURL };
export const encriptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
export const uploadURL = process.env.REACT_APP_UPLOAD_URL;
export const postRequest = async (url, token, body = {}, headers = {}) => {
  let xform = qs.stringify(body);

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/x-www-form-urlencoded",
      ...headers,
    },
  };

  let returnValue;

  await axios
    .post(baseURL + url, xform, config)
    .then((result) => {
      returnValue = { result: result, error: null };
    })
    .catch((err) => {
      returnValue = { result: null, error: err };
    });
  return returnValue;
};

export const postRequestForm = async (url, token, body = {}, headers = {}) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      ...headers,
    },
  };

  let returnValue;
  await axios
    .post(baseURL + url, body, config)
    .then((result) => {
      returnValue = { result: result, error: null };
    })
    .catch((err) => {
      returnValue = { result: null, error: err };
    });
  return returnValue;
};

export const postWithParams = async (url, token, params = {}, headers = {}) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/x-www-form-urlencoded",
      ...headers,
    },
    params: {
      ...params,
    },
  };

  let returnValue;

  await axios
    // baseURL +
    .post(baseURL + url, {}, config)
    .then((result) => {
      returnValue = { result: result, error: null };
    })
    .catch((err) => {
      returnValue = { result: null, error: err };
    });
  return returnValue;
};

export const getRequest = async (
  url,
  token,
  params = {},
  headers = {},
  signal = null
) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/x-www-form-urlencoded",
      ...headers,
    },
    params: {
      ...params,
    },
    signal: signal, // Adding the signal for AbortController
  };

  let returnValue;

  await axios
    // baseURL +
    .get(baseURL + url, config)
    .then((result) => {
      returnValue = { result: result, error: null };
    })
    .catch((err) => {
      returnValue = { result: null, error: err };
    });
  return returnValue;
};

export const deleteRequest = async (url, body = {}, token, headers = {}) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      ...headers,
    },
    data: {
      ...body,
    },
  };

  let returnValue;

  await axios
    // baseURL +
    .delete(baseURL + url, config)
    .then((result) => {
      returnValue = { result: result, error: null };
    })
    .catch((err) => {
      returnValue = { result: null, error: err };
    });
  return returnValue;
};

export const putRequest = async (url, token, body = {}, headers = {}) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
      ...headers,
    },
  };
  let returnValue;

  await axios
    .put(baseURL + url, body, config)
    .then((result) => {
      returnValue = { result: result, error: null };
    })
    .catch((err) => {
      returnValue = { result: null, error: err };
    });
  return returnValue;
};
