import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  FormControl,
  Row,
  Alert,
  Form,
  Modal,
} from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faPlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import Layout from "../../layouts/Layout";
import BackButton from "../../constants/BackButton";
import ReactImageUploading from "react-images-uploading";
import { Images } from "../../constants/Images";
import PairButtons from "../../constants/PairButtons";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getRequest,
  postRequest,
  postRequestForm,
  uploadURL,
} from "../../helper/api";
import { toast } from "react-toastify";

const CreatePlayer = () => {
  const navigate = useNavigate();
  let location,
    { state, search } = useLocation();

  const [playerName, setPlayerName] = useState("");
  const [battingStyle, setBattingStyle] = useState("");
  const [bowlingStyle, setBowlingStyle] = useState("N/A");
  const [wicketKeeper, setWicketKeeper] = useState(false);
  const [playerImage, setPlayerImage] = useState([]);
  const [featuredImage, setFeaturedImage] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [playerId, setPlayerId] = useState("");

  const [name, setName] = useState("");
  const [flags, setFlags] = useState([]);
  const [selectedPlayers, setSelectedPlayers] = useState([]);


    const paceOptions = ["Right Arm Fast", "Left Arm Fast"];
    const spinOptions = [
      "Right Arm Off Spin",
      "Right Arm Leg Spin",
      "Left Arm Off Spin",
      "Left Arm Leg Spin",
    ];

    const handlePaceChange = (e) => {
      setBowlingStyle(e.target.value);
    };

    const handleSpinChange = (e) => {
      setBowlingStyle(e.target.value);
    };

  // Check if editing a player
  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const id = queryParams.get("_id");
    console.log("a-id", id);
    if (id) {
      setIsEdit(true);
      fetchPlayerDetails(id);
    }
  }, [location]);

  useEffect(() => {
    console.log("state", state);
    if (state) {
      setName(state?.teamName);
      setFlags(state?.images);
      setSelectedPlayers(state?.selectedPlayers);
    }
  }, [state]);

  // Fetch Player Details for Editing
  const fetchPlayerDetails = async (id) => {
    try {
      const { result, error } = await getRequest(
        `/api/secure/player/get-player?_id=${id}`
      );
      if (result?.status === 200) {
        const player = result.data.player;
        setPlayerId(player._id);
        setPlayerName(player.playerName);
        setBattingStyle(player.battingStyle);
        setBowlingStyle(player.bowlingStyle);
        setWicketKeeper(player.wicketKeeper);
        if (player.profileImage) {
          setPlayerImage([{ data_url: `${uploadURL}${player.profileImage}` }]);
          setFeaturedImage(player.profileImage);
        }
      } else {
        throw new Error(
          error?.response?.data?.message || "Failed to fetch player details"
        );
      }
    } catch (err) {
      toast.error(err.message, { position: "top-center" });
    }
  };

  // Handle Image Upload
  const onChangeImage = (imageList, addUpdateIndex) => {
    setPlayerImage(imageList);
    if (imageList[addUpdateIndex]) {
      setFeaturedImage(imageList[addUpdateIndex].file);
    }
  };

  // Handle Form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!playerName || !battingStyle) {
      toast.error("Please fill in all required fields.", {
        position: "top-center",
      });
      return;
    }

    try {
      const endpoint = isEdit
        ? `/api/secure/player/edit-player`
        : `/api/secure/player/register-player`;

      const formData = new FormData();
      formData.append("playerName", playerName);
      formData.append("battingStyle", battingStyle);
      formData.append("bowlingStyle", bowlingStyle);
      formData.append("wicketKeeper", wicketKeeper);

      if (featuredImage) {
        formData.append("profileImage", featuredImage);
      } else {
        toast.error("Please Upload the Thumbnail Image for a Player", {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        return false;
      }

      if (isEdit) {
        formData.append("_id", playerId);
      }

      const { result, error } = await postRequestForm(endpoint, "", formData);

      if (result?.status === 200 || result?.status === 201) {
        toast.success(
          `Player ${isEdit ? "updated" : "created"} successfully.`,
          { position: "top-center" }
        );

        const { player } = result.data;

        // Update the selectedPlayers array
        setSelectedPlayers((prev) => {
          const existingPlayerIndex = prev?.findIndex(
            (p) => p._id === player._id
          );
          if (existingPlayerIndex !== -1) {
            // Update the existing player
            const updatedPlayers = [...prev];
            updatedPlayers[existingPlayerIndex] = {
              _id: player._id,
              selected: true,
              name: player.playerName,
              image: player.profileImage,
            };
            return updatedPlayers;
          } else {
            // Add the new player
            return [
              ...prev,
              {
                _id: player._id,
                selected: true,
                name: player.playerName,
                image: player.profileImage,
              },
            ];
          }
        });

        // Navigate with updated state
        navigate(`/team?_id=${state?.teamId || ""}`, {
          state: {
            players: selectedPlayers.map((p) =>
              p._id === player._id
                ? {
                    _id: player._id,
                    selected: true,
                    name: player.playerName,
                    image: player.profileImage,
                  }
                : p
            ),
            teamName: name,
            images: flags,
            isEdit: state?.isEdit,
            teamId: state?.teamId || null,
          },
        });
        console.log(
          "selectedPlayers after adding/updating new player",
          selectedPlayers
        );
      } else {
        throw new Error(error?.response?.data?.message || "Operation failed");
      }
    } catch (err) {
      toast.error(err.message, { position: "top-center" });
    }
  };


  const resetBowlingStyle = () => {
    setBowlingStyle("");
  };

  return (
    <Layout>
      <section
        id="createTeam"
        style={{
          backgroundImage: `url(${Images.background2})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <BackButton
          icon={Images.backArrow}
          content="Back"
          onClick={() => {
            if (state?.isEdit) {
              navigate(`/team?_id=${state.teamId}`, {
                state: {
                  players: selectedPlayers,
                  teamName: name,
                  images: flags,
                  isEdit: state?.isEdit,
                  teamId: state.teamId,
                  existingPlayers: state.existingPlayers,
                  tournamentName: state?.tournamentName || null,
                  tournamentType: state?.tournamentType || null,
                  startDate: state?.startDate || null,
                  endDate: state?.endDate || null,
                },
              });
            } else {
              navigate("/team", {
                state: {
                  players: selectedPlayers,
                  teamName: name,
                  images: flags,
                  isEdit: false,
                  teamId: null,
                  tournamentName: state?.tournamentName || null,
                  tournamentType: state?.tournamentType || null,
                  startDate: state?.startDate || null,
                  endDate: state?.endDate || null,
                },
              });
            }
          }}
        />

        <Container className="list-container w-75">
          <Row className="align-items-start">
            {/* Player Name */}
            <Col lg={10} md={10} xs={10}>
              <span className="pb-3 heading d-flex align-items-center border-0">
                <p className="mb-0 me-3">PLAYER NAME:</p>
                <FormControl
                  type="text"
                  placeholder="Name"
                  className="w-50"
                  value={playerName}
                  onChange={(e) => setPlayerName(e.target.value)}
                />
              </span>

              {/* Batting Style */}
              <span className="pb-3 heading d-flex align-items-center border-0">
                <p className="mb-0 me-3">BATTING STYLE:</p>
                <Form.Check
                  inline
                  label="RHB"
                  name="battingStyle"
                  type="radio"
                  id="battingStyle-rhb"
                  value="RHB"
                  className="me-5"
                  checked={battingStyle === "RHB"}
                  onChange={(e) => setBattingStyle(e.target.value)}
                />
                <Form.Check
                  inline
                  label="LHB"
                  name="battingStyle"
                  type="radio"
                  id="battingStyle-lhb"
                  value="LHB"
                  className="ms-5"
                  checked={battingStyle === "LHB"}
                  onChange={(e) => setBattingStyle(e.target.value)}
                />
              </span>

              {/* Wicket Keeper */}
              <span className="pb-3 heading d-flex align-items-center border-0">
                <p className="mb-0 me-3">WICKET KEEPER:</p>
                <Form.Check
                  inline
                  label="YES"
                  name="wicketKeeper"
                  type="radio"
                  id="wicketKeeper-yes"
                  value="true"
                  className="me-5"
                  checked={wicketKeeper === true}
                  onChange={() => setWicketKeeper(true)}
                />
                <Form.Check
                  inline
                  label="NO"
                  name="wicketKeeper"
                  type="radio"
                  id="wicketKeeper-no"
                  value="false"
                  className="ms-5"
                  checked={wicketKeeper === false}
                  onChange={() => setWicketKeeper(false)}
                />
              </span>

              {/* Bowling Style */}
              {/* <span className="pb-3 heading d-flex border-0">
                <p className="mb-0 w-50">BOWLING STYLE:</p>
                <Row>
                  {bowlingStyles.map((style, index) => (
                    <Col key={index} xs={12} md={6} className="mb-2">
                      <Form.Check
                        inline
                        label={style}
                        name="bowlingStyle"
                        type="radio"
                        id={`bowlingStyle-${index}`}
                        value={style}
                        checked={bowlingStyle === style}
                        onChange={(e) => setBowlingStyle(e.target.value)}
                      />
                    </Col>
                  ))}
                </Row>
              </span> */}

              {/* Bowling Style */}
              <Col xs={12}>
                <Form.Group className="heading border-0 d-flex align-items-center">
                  <p className="mb-0 pt-3">BOWLING STYLE:</p>
                  <Row className="w-100">
                    {/* Pace Options */}
                    <Col xs={6}>
                      <Form.Group className="position-relative">
                        <Form.Select
                          disabled={spinOptions.some(
                            (opt) => opt === bowlingStyle
                          )}
                          onChange={handlePaceChange}
                          value={
                            paceOptions.includes(bowlingStyle)
                              ? bowlingStyle
                              : ""
                          }
                        >
                          <option value="">Select Pace Style</option>
                          {paceOptions.map((pace, index) => (
                            <option key={index} value={pace}>
                              {pace}
                            </option>
                          ))}
                        </Form.Select>
                        {bowlingStyle && paceOptions.includes(bowlingStyle) && (
                          <Button
                            variant="light"
                            className=""
                            onClick={resetBowlingStyle}
                            style={{
                              position: "absolute",
                              right: "20px",
                              bottom: "0",
                              border: "none",
                              background: "none",
                              fontSize: "1.2rem",
                              color: "#dc3545",
                            }}
                          >
                            x
                          </Button>
                        )}
                      </Form.Group>
                    </Col>

                    {/* Spin Options */}
                    <Col xs={6}>
                      <Form.Group className="position-relative">
                        <Form.Select
                          disabled={paceOptions.some(
                            (opt) => opt === bowlingStyle
                          )}
                          onChange={handleSpinChange}
                          value={
                            spinOptions.includes(bowlingStyle)
                              ? bowlingStyle
                              : ""
                          }
                        >
                          <option value="">Select Spin Style</option>
                          {spinOptions.map((spin, index) => (
                            <option key={index} value={spin}>
                              {spin}
                            </option>
                          ))}
                        </Form.Select>
                        {bowlingStyle && spinOptions.includes(bowlingStyle) && (
                          <Button
                            variant="light"
                            className=""
                            onClick={resetBowlingStyle}
                            style={{
                              position: "absolute",
                              right: "20px",
                              bottom: "0",
                              border: "none",
                              background: "none",
                              fontSize: "1.2rem",
                              color: "#dc3545",
                            }}
                          >
                            x
                          </Button>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Col>

            {/* Player Image */}
            <Col lg={2} md={2} xs={2}>
              <ReactImageUploading
                value={playerImage}
                onChange={onChangeImage}
                dataURLKey="data_url"
                maxNumber={1}
                maxFileSize={10000000}
                acceptType={["jpeg", "jpg", "gif", "png"]}
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageUpdate,
                  isDragging,
                  dragProps,
                }) => (
                  <>
                    <div className="upload__image-wrapper mb-3">
                      {imageList?.length === 0 ? (
                        <div
                          className="image-item d-flex"
                          style={{
                            border: "1px solid #ccc",
                            cursor: "pointer",
                            width: "120px",
                            height: "144px",
                            justifyContent: "center",
                            alignItems: "center",
                            ...(isDragging ? { color: "red" } : {}),
                          }}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </div>
                      ) : (
                        imageList?.map((image, index) => (
                          <div key={index} className="image-item">
                            <LazyLoadImage
                              src={image["data_url"]}
                              width={65}
                              height={65}
                              alt="uploaded"
                            />
                            <Button
                              className="position-absolute"
                              variant="link"
                              onClick={() => onImageUpdate(index)}
                            >
                              <FontAwesomeIcon icon={faPencilAlt} />
                            </Button>
                          </div>
                        ))
                      )}
                    </div>
                  </>
                )}
              </ReactImageUploading>
            </Col>
          </Row>

          <br />
          {/* Submit Buttons */}
          <PairButtons
            content1="CANCEL"
            onClick1={() => {
              if (state?.isEdit) {
                navigate(`/team?_id=${state.teamId}`, {
                  state: {
                    players: selectedPlayers,
                    teamName: name,
                    images: flags,
                    isEdit: state?.isEdit,
                    teamId: state.teamId,
                    existingPlayers: state.existingPlayers,
                    tournamentName: state?.tournamentName || null,
                    tournamentType: state?.tournamentType || null,
                    startDate: state?.startDate || null,
                    endDate: state?.endDate || null,
                  },
                });
              } else {
                navigate("/team", {
                  state: {
                    players: selectedPlayers,
                    teamName: name,
                    images: flags,
                    isEdit: false,
                    teamId: null,
                    tournamentName: state?.tournamentName || null,
                    tournamentType: state?.tournamentType || null,
                    startDate: state?.startDate || null,
                    endDate: state?.endDate || null,
                  },
                });
              }
            }}
            icon1={Images.backArrowButton}
            backgroundColor1="#fff"
            color1="#000"
            content2={isEdit ? "UPDATE" : "CREATE"}
            onClick2={handleSubmit}
            icon2={Images.whiteNextArrow}
            backgroundColor2="rgba(231, 50, 147, 1)"
            color2="#fff"
          />
        </Container>
      </section>
    </Layout>
  );
};

export default CreatePlayer;
