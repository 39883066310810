import { Container, InputGroup } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const EditTournamentSkeleton = () => {
  return (
    <Container className="image-input-container mt-5">
      <SkeletonTheme baseColor="#fff" highlightColor="#ddd">
        {/* Tournament Name */}
        <Skeleton width={"100%"} height={40} />
        <br />

        {/* Tournament Type */}
        <Skeleton width={"100%"} height={40} />
        <br />

        {/* Start Date */}

        <Skeleton width={"100%"} height={40} />
        <br />

        {/* End Date */}

        <Skeleton width={"100%"} height={40} />
        <br />

        {/* Add Teams Button */}
        <Skeleton width={"100%"} height={40} />
      </SkeletonTheme>
    </Container>
  );
};

export default EditTournamentSkeleton;
