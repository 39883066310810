import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Container,
  Modal,
  Form,
  Col,
  Row,
  FormControl,
} from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faEdit,
  faTrash,
  faPencilAlt,
  faPlus,
  faBriefcaseClock,
  faTrashRestore,
  faUserLock,
  faUserSlash,
} from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import Skeleton from "react-loading-skeleton";
import Layout from "../../../layouts/Layout";
import { Images } from "../../../constants/Images";
import BackButton from "../../../constants/BackButton";
import { toast } from "react-toastify";
import routes from "../../../helper/routes";
import Select from "react-select";
import ReactImageUploading from "react-images-uploading";
import { useNavigate } from "react-router-dom";
import { getItemFromLocalStorage } from "../../../helper/helper";
import {
  deleteRequest,
  getRequest,
  postRequest,
  postRequestForm,
  putRequest,
} from "../../../helper/api";
import { AsyncPaginate } from "react-select-async-paginate";
import SingleButton from "../../../constants/SingleButton";
import TableSkeleton from "../../../constants/Skeleton Loaders/TableSkeleton";

const AllSuperAdmin = () => {
  const [admins, setAdmins] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState([]);
  const [tableHead, setTableHead] = useState();
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedSuperAdmin, setSelectedSuperAdmin] = useState(null);
  const [modalFunction, setModalFunction] = useState("Creete User");
  const [shouldFetch, setShouldFetch] = useState(false);

  const navigate = useNavigate();

  const handleShowModal = (modalFunction) => {
    setShowModal(true);
    setModalFunction(modalFunction);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedSuperAdmin(null);
    getAllAdmins(currentPage, perPage);
  };

  const handleSearchCategory = (e) => {
    const searchValue = e.target.value;
    setSearch(searchValue);
  };

  const handleBlockRecover = async (id, status) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      if (status === "active") {
        const { result, error } = await postRequestForm(
          `/api/secure/v2/SuperAdmin/block`,
          token,
          { userId: id }
        );
        if (result?.status === 200 || result?.status === 201) {
          toast.success("Super Admin Blocked successfully.", {
            position: "top-center",
          });
          getAllAdmins(currentPage, perPage);
        } else if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message, {
            position: "top-center",
            theme: "colored",
          });
        }
      } else {
        const { result, error } = await postRequestForm(
          `/api/secure/v2/SuperAdmin/recover`,
          token,
          { userId: id }
        );
        if (result?.status === 200 || result?.status === 201) {
          toast.success("Super Admin Recovered successfully.", {
            position: "top-center",
          });
          getAllAdmins(currentPage, perPage);
        } else if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message, {
            position: "top-center",
            theme: "colored",
          });
        }
      }
    } catch (err) {
      toast.error(err.message, {
        position: "top-center",
      });
    }
  };

  const getAllAdmins = useCallback(
    async (currentPage, perPage, search) => {
      try {
        setLoading(true);
        const token = getItemFromLocalStorage("TOKEN");
        const { result, error } = await getRequest(
          `/api/secure/v2/SuperAdmin/get-all`,
          token,
          { page: currentPage, limit: perPage, search: search }
        );

        if (result?.status === 200 || result?.status === 201) {
          setTableData(result?.data?.data);
          setTotalRows(result?.data?.totalUsers);
        } else {
          throw new Error(
            error?.response?.data?.message || "An error occurred."
          );
        }
        setTableHead([
          {
            name: "Profile",
            maxWidth: "40px",
            center: true,

            selector: (row) => (
              <LazyLoadImage
                src={row?.profileImageUrl || Images.profile}
                alt="icon"
                width={30}
                height={30}
                style={{
                  borderRadius: "50%",
                  objectFit: "cover",
                  objectPosition: "center",
                  border: "1px solid rgba(231, 50, 147, 1)",
                }}
              />
            ),
          },
          {
            name: "Name",
            selector: (row) => row.fullName,
            sortable: true,
          },
          {
            name: "Actions",
            maxWidth: "83px",
            cell: (row) => (
              <>
                <Button
                  variant="primary"
                  size="sm"
                  className="me-2 my-3"
                  onClick={() => {
                    setSelectedSuperAdmin(row);
                    handleShowModal("Update Admin");
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
                {/* <Button
                  variant="danger"
                  size="sm"
                  className="me-2 my-3"
                  onClick={() => handleDeleteSuperAdmin(row._id)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button> */}
                {row?.status === "active" ? (
                  <Button
                    variant="danger"
                    size="sm"
                    className=" my-3"
                    onClick={() => {
                      handleBlockRecover(row._id, row.status);
                    }}
                  >
                    <FontAwesomeIcon icon={faUserSlash} title="Block Admin" />
                  </Button>
                ) : (
                  <Button
                    variant="success"
                    size="sm"
                    className="my-3"
                    onClick={() => {
                      handleBlockRecover(row._id, row.status);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faTrashRestore}
                      title="Unblock Admin"
                    />
                  </Button>
                )}
              </>
            ),
          },
        ]);
      } catch (err) {
        toast.error(err.message || "An error occurred.", {
          position: "top-center",
        });
      } finally {
        setLoading(false);
      }
    },
    [currentPage, perPage, search] // Stable reference
  );

  const handlePageChange = (page) => {
    console.log("Page changed to:", page);
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    console.log("Rows per page changed to:", newPerPage, page);
    setPerPage(newPerPage);
    setCurrentPage(page); // Reset to the first page
  };

  useEffect(() => {
    getAllAdmins(currentPage, perPage);
  }, [currentPage, perPage, getAllAdmins, search]);

  const SkeletonTable = () => {
    const skeletonRows = Array(5).fill(0);

    return (
      <div>
        {skeletonRows.map((_, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <span style={{ display: "flex", alignItems: "center" }}>
              <Skeleton
                circle
                width={20}
                height={20}
                style={{ marginRight: "20px" }}
              />
              <Skeleton width={100} height={20} />
            </span>
            <Skeleton width={60} height={20} />
          </div>
        ))}
      </div>
    );
  };

  const handleDeleteSuperAdmin = async (id) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await deleteRequest(
        `/api/secure/v2/SuperAdmin/delete`,
        { userId: id },
        token
      );
      if (result?.status === 200 || result?.status === 201) {
        toast.success("Super Admin deleted successfully.", {
          position: "top-center",
        });
        getAllAdmins(currentPage, perPage);
      } else if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message, {
          position: "top-center",
          theme: "colored",
        });
      }
    } catch (err) {
      toast.error(err.message, {
        position: "top-center",
      });
    }
  };
  return (
    <Layout>
      <section
        id="startMatch"
        style={{
          backgroundImage: `url(${Images.background2})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container>
          <div className="d-flex justify-content-between align-items-center">
            <BackButton
              icon={Images.backArrow}
              content="Back"
              onClick={() => navigate("/dashboard")}
            />

            <SingleButton
              variant="primary"
              backgroundColor={"#fff"}
              color={"rgba(231, 50, 147, 1)"}
              content={"Create Admin"}
              onClick={() => {
                setSelectedSuperAdmin(null);
                handleShowModal("Create Admin");
              }}
              buttonStyles={
                {
                  // width: "100%",
                }
              }
            />
          </div>
        </Container>
        <Container className="logo-table-container mt-5">
          {/* <LazyLoadImage
            className="logo mt-3 mb-5"
            src={Images.logo}
            width={100}
            alt="Logo"
          /> */}
          <div className="table-container">
            <Row className="mb-3">
              <Col xs={12} className="mt-3">
                <FormControl
                  type="text"
                  placeholder="Search Admin"
                  className="me-2"
                  value={search}
                  onChange={handleSearchCategory}
                />
              </Col>
            </Row>
            <br />
            {loading ? (
              <TableSkeleton
                row={5}
                head={[
                  {
                    name: "Profile",
                    className:
                      "sc-fAUdSK sc-dntaoT sc-ivxoEo imMSkv elpSoT bOmZtP rdt_TableCell",
                    width: 40,
                    height: 40,
                    circle: true,
                  },
                  {
                    name: "Name",
                    className:
                      "sc-fAUdSK sc-dntaoT sc-ivxoEo imMSkv dQcPXM bOmZtP rdt_TableCell",
                    width: 150,
                    height: 20,
                  },
                  {
                    name: "Actions",
                    className:
                      "sc-fAUdSK sc-dntaoT sc-ivxoEo imMSkv bZgVeN jEzKnw rdt_TableCell",
                    width: [30, 30],
                    height: [30, 30],
                  },
                ]}
              />
            ) : (
              <DataTable
                columns={tableHead}
                data={tableData}
                paginationPerPage={perPage}
                paginationTotalRows={totalRows}
                paginationDefaultPage={currentPage}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                pagination
                paginationServer
                paginationRowsPerPageOptions={[5, 10, 15, 20]}
                highlightOnHover
                responsive
                customStyles={{
                  headCells: {
                    style: {
                      border: "1px solid #ccc",
                      color: "#fff",
                    },
                  },
                  cells: {
                    style: {
                      border: "1px solid #ccc",
                      color: "#fff",
                    },
                  },
                }}
              />
            )}
          </div>

          <SuperAdminModal
            show={showModal}
            onHide={handleCloseModal}
            adminData={selectedSuperAdmin}
            modalFunction={modalFunction}
          />
        </Container>
      </section>
    </Layout>
  );
};

const SuperAdminModal = ({ show, onHide, adminData, modalFunction }) => {
  useEffect(() => {
    if (adminData) {
      const user = adminData;
      const {
        firstName,
        lastName,
        email,
        profileImage,
        profileImageUrl,
        // permissions,
        // trialPeriod,
        roleName,
      } = user;
      setUserId(user._id);
      setFirstName(firstName);
      setLastName(lastName);
      setEmail(email);
      setProfileImageUrl(profileImageUrl);
      if (password) {
        setPassword(password);
      }
      if (roleName) {
        setRole(roleName);
      }

      if (profileImage) {
        setImageList([{ data_url: `${profileImageUrl}` }]);
      }
    } else {
      setFirstName("");
      setLastName("");
      setEmail("");
      setProfileImageUrl("");
      setImageList([]);
      setFeaturedImage(null);
      setLoading(false);
      setRole("");
      setPassword("");
      // setPermissions([]);
      // setTrial(null);
    }
  }, [adminData]);

  const [userId, setUserId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [profileImageUrl, setProfileImageUrl] = useState("");
  const [imageList, setImageList] = useState([]);
  const [featuredImage, setFeaturedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("");

  const onChangeImage = (imageList, addUpdateIndex) => {
    setImageList(imageList);
    if (imageList[addUpdateIndex]) {
      setFeaturedImage(imageList[addUpdateIndex].file);
    }
  };

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (firstName === "") {
        toast.error("First Name is required.", {
          position: "top-center",
        });
        return false;
      }
      if (lastName === "") {
        toast.error("Last Name is required.", {
          position: "top-center",
        });
        return false;
      }
      if (email === "") {
        toast.error("Email is required.", {
          position: "top-center",
        });
        return false;
      }

      const token = getItemFromLocalStorage("TOKEN");

      const formData = new FormData();
      formData.append("firstName", firstName);
      formData.append("lastName", lastName);

      formData.append("email", email);
      if (featuredImage) formData.append("profileImage", featuredImage);
      if (modalFunction === "Create Admin") {
        if (!password) {
          toast.error("Password is required.", {
            position: "top-center",
          });
          return false;
        } else {
          if (password?.length < 6) {
            toast.error("Password must be greater than 6.", {
              position: "top-center",
            });
            return false;
          }
          formData.append("password", password);
        }

        console.log("create Admin formData", Object.fromEntries(formData));
        const { result, error } = await postRequestForm(
          `/api/secure/v2/SuperAdmin/create`,
          token,
          formData
        );

        if (result?.status === 200 || result?.status === 201) {
          toast.success("User Created successfully!", {
            position: "top-center",
          });

          onHide();
        } else if (error?.response?.status === 403) {
          toast.error(error?.response?.data?.message, {
            position: "top-center",
          });
        } else if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message || "An error occurred.", {
            position: "top-center",
          });
        }
      } else {
        if (password) {
          if (password?.length < 6) {
            toast.error("Password must be greater than 6.", {
              position: "top-center",
            });
            return false;
          }
          formData.append("password", password);
        }
        formData.append("userId", userId);
        console.log("update Admin formData", Object.fromEntries(formData));
        const { result, error } = await putRequest(
          `/api/secure/v2/SuperAdmin/editSuperAdmin`,
          token,
          formData
        );
        console.log("result, error", result, error);
        if (result?.status === 200 || result?.status === 201) {
          toast.success("User Updated successfully!", {
            position: "top-center",
          });

          onHide();
        } else if (error?.response?.status === 403) {
          toast.error(error?.response?.data?.message, {
            position: "top-center",
          });
        } else if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message || "An error occurred.", {
            position: "top-center",
          });
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      className="teams-modal"
    >
      <section id="profileSettings" className="">
        <Container className="list-container">
          <div
            className="d-flex justify-content-between align-items-center pb-2 "
            style={{
              borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
              marginBottom: "1rem",
            }}
          >
            <h3 className="heading border-0 m-0 p-0">{modalFunction}</h3>
            <Button
              onClick={() => {
                onHide();
              }}
              style={{
                backgroundColor: "transparent",
                border: "none",
                ":hover": {
                  backgroundColor: "transparent",
                },
                ":focus,:active": {
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  border: "none",
                },
              }}
            >
              <FontAwesomeIcon
                icon={faTimes}
                color="#fff"
                style={{
                  fontSize: "24px",
                  cursor: "pointer",
                }}
              />
            </Button>
          </div>
          <Row className="align-items-center justify-content-center">
            <Col xs={12}>
              <div className="profile-card v2 bg-transparent p-0 border-0 shadow-none">
                <Row className="align-items-center">
                  <Col
                    lg={4}
                    md={4}
                    xs={12}
                    className="d-flex justify-content-center justify-content-md-start"
                  >
                    <ReactImageUploading
                      value={imageList}
                      onChange={onChangeImage}
                      dataURLKey="data_url"
                      maxNumber={1}
                      maxFileSize={10000000}
                      acceptType={["jpeg", "jpg", "gif", "png"]}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageUpdate,
                        isDragging,
                        dragProps,
                      }) => (
                        <>
                          <div className="upload__image-wrapper mb-3">
                            {imageList?.length === 0 ? (
                              <div
                                className="image-item d-flex"
                                style={{
                                  border: "1px solid #ccc",
                                  cursor: "pointer",
                                  width: "120px",
                                  height: "120px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  backgroundImage: `url(${
                                    profileImageUrl || Images.profile
                                  })`,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                  backgroundBlendMode: "overlay",
                                  backgroundColor: "rgba(231, 50, 147, .5)",
                                  border: "1px solid rgba(231, 50, 147, 1)",
                                  borderRadius: "50%",
                                  ...(isDragging ? { color: "red" } : {}),
                                }}
                                onClick={onImageUpload}
                                {...dragProps}
                              >
                                <FontAwesomeIcon
                                  icon={faPlus}
                                  color="#000"
                                  style={{
                                    fontSize: "30px",
                                  }}
                                />
                              </div>
                            ) : (
                              imageList?.map((image, index) => (
                                <div key={index} className="image-item">
                                  <LazyLoadImage
                                    src={image["data_url"]}
                                    width={65}
                                    height={65}
                                    style={{ borderRadius: "50%" }}
                                    alt="uploaded"
                                  />
                                  <Button
                                    className="position-absolute"
                                    variant="link"
                                    onClick={() => onImageUpdate(index)}
                                  >
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                  </Button>
                                </div>
                              ))
                            )}
                          </div>
                        </>
                      )}
                    </ReactImageUploading>
                  </Col>
                  <Col
                    lg={8}
                    md={8}
                    xs={12}
                    className="text-center text-md-start"
                  >
                    <h3
                      style={{ wordBreak: "break-all" }}
                    >{`${firstName} ${lastName}`}</h3>
                    <p style={{ wordBreak: "break-all" }}> {email}</p>
                  </Col>
                </Row>
                <Row className="text-input-container">
                  <Col xs={12} sm={5}>
                    <p>First Name:</p>
                  </Col>
                  <Col xs={12} sm={7}>
                    <FormControl
                      className="text-start text-sm-end text-light"
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row className="text-input-container">
                  <Col xs={12} sm={5}>
                    <p>Last Name:</p>
                  </Col>
                  <Col xs={12} sm={7}>
                    <FormControl
                      className="text-start text-sm-end text-light"
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                    />
                  </Col>
                </Row>

                <Row className="text-input-container">
                  <Col xs={12} sm={5}>
                    <p>Email:</p>
                  </Col>
                  <Col xs={12} sm={7}>
                    <FormControl
                      className="text-start text-sm-end text-light"
                      type="text"
                      name="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      style={{
                        backgroundImage: "unset !important",
                      }}
                    />
                  </Col>
                </Row>
                <Row className="text-input-container">
                  <Col xs={12} sm={5}>
                    <p>Password:</p>
                  </Col>
                  <Col xs={12} sm={7}>
                    <FormControl
                      className="text-start text-sm-end text-light"
                      type="password"
                      name="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </Col>
                </Row>

                {/* <Row className="text-input-container gap-1">
                  <Col xs={12}>
                    <p>Permissions:</p>
                  </Col>
                  <Col xs={12}>
                    <AsyncPaginate
                      value={permissions ? permissions : ""}
                      loadOptions={fetchPermisions}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option._id}
                      onChange={handlePermissionsChange}
                      placeholder="Select Permissions"
                      isClearable
                      loadingMessage={() => "Loading..."}
                      noOptionsMessage={() => "No Permissions Found"}
                      additional={{
                        page: 1,
                      }}
                      isMulti
                      isSearchable
                      styles={{
                        menu: (base) => ({
                          ...base,
                          border: 0,
                          backgroundColor: "rgb(164, 207, 255)",
                          // open upwards
                          top: "auto",
                          // open upwards
                          bottom: "100%",
                          margin: 0,
                        }),
                        option: (
                          styles,
                          { data, isDisabled, isFocused, isSelected }
                        ) => {
                          return {
                            ...styles,
                            backgroundColor: isSelected
                              ? "rgba(16, 14, 52, 1)"
                              : isFocused
                              ? "rgb(164, 207, 255)"
                              : null,
                            color: isSelected ? "white" : "black",
                          };
                        },
                      }}
                    />
                  </Col>
                </Row>
                <Row className="text-input-container">
                  <Col xs={12}>
                    <p>Trial Period:</p>
                  </Col>
                  <Col xs={12}>
                    <Select
                      options={trialOptions}
                      value={trial}
                      onChange={handleTrialChange}
                      placeholder="Select Trial Period"
                      styles={{
                        menu: (base) => ({
                          ...base,
                          border: 0,
                          backgroundColor: "rgb(164, 207, 255)",
                          // open upwards
                          top: "auto",
                          // open upwards
                          bottom: "100%",
                          margin: 0,
                        }),
                        option: (
                          styles,
                          { data, isDisabled, isFocused, isSelected }
                        ) => {
                          return {
                            ...styles,
                            backgroundColor: isSelected
                              ? "rgba(16, 14, 52, 1)"
                              : isFocused
                              ? "rgb(164, 207, 255)"
                              : null,
                            color: isSelected ? "white" : "black",
                          };
                        },
                      }}
                    />
                  </Col>
                </Row> */}

                <Row className="text-input-container border-0">
                  <Col xs={12} className="d-flex justify-content-center ">
                    <Button
                      onClick={handleSaveChanges}
                      variant="primary"
                      className="btn-default w-100"
                      style={{
                        height: "40px",
                        fontSize: "20px",
                      }}
                      disabled={loading}
                    >
                      {loading ? (
                        <span className="spinner-border spinner-border-sm"></span>
                      ) : (
                        `${modalFunction}`
                      )}
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Modal>
  );
};

export default AllSuperAdmin;
