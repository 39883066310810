import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, Col, Container, Row } from "react-bootstrap";
import Layout from "../../layouts/Layout";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Input from "../../constants/Input";
import { Images } from "../../constants/Images";
import { postRequestForm } from "../../helper/api";
import { toast } from "react-toastify";
import {
  getItemFromLocalStorage,
  removeItemFromLocalStorage,
} from "../../helper/helper";
import BackButton from "../../constants/BackButton";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  // Validation Schema
  const passwordSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Old password is required."),
    newPassword: Yup.string()
      .required("New password is required.")
      .min(6, "Password must be at least 6 characters."),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match.")
      .required("Confirm password is required."),
  });

  // Password Change Handler
  const changePasswordHandler = async (values, { resetForm }) => {
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");

      const user = JSON.parse(getItemFromLocalStorage("USER"));

      const { result, error } = await postRequestForm(
        `/api/secure/v2/${user?.roleName.replace(" ", "")}/change-password`,
        token,
        {
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
        }
      );

      if (result?.status === 200) {
        toast.success("Password updated successfully.");
        removeItemFromLocalStorage("TOKEN");
        removeItemFromLocalStorage("USER");

        window.location.reload();
      } else {
        throw new Error(
          error?.response?.data?.message || "Password update failed."
        );
      }
    } catch (err) {
      toast.error(err.message || "An error occurred.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <section
        id="newPassword"
        style={{
          backgroundImage: `url(${Images.background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container>
          <BackButton
            content={"Back"}
            icon={Images.backArrow}
            onClick={() => navigate("/dashboard")}
          />

          <Row className="justify-content-center">
            <Col lg={4} md={8} xs={12}>
              {/* Logo */}
              <div className="image-container text-center mb-5">
                <LazyLoadImage src={Images.logo} className="w-25" alt="Logo" />
              </div>

              {/* Password Reset Form */}
              <Formik
                initialValues={{
                  oldPassword: "",
                  newPassword: "",
                  confirmPassword: "",
                }}
                validationSchema={passwordSchema}
                onSubmit={changePasswordHandler}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="text-start">
                      <h1 className="newPassword">CHANGE PASSWORD</h1>
                      <p className="text mb-3">
                        Update your account password for better security.
                      </p>
                    </div>

                    {/* Old Password Input */}
                    <Input
                      formType="password"
                      formPlaceholder="OLD PASSWORD"
                      formHandleChange={handleChange}
                      formHandleBlur={handleBlur}
                      formName="oldPassword"
                      formValue={values.oldPassword}
                      icon={Images.lock}
                      size="lg"
                      iconColor="white"
                    />
                    {errors.oldPassword && touched.oldPassword && (
                      <div className="text-danger">{errors.oldPassword}</div>
                    )}

                    {/* New Password Input */}
                    <Input
                      formType="password"
                      formPlaceholder="NEW PASSWORD"
                      formHandleChange={handleChange}
                      formHandleBlur={handleBlur}
                      formName="newPassword"
                      formValue={values.newPassword}
                      icon={Images.lock}
                      size="lg"
                      iconColor="white"
                    />
                    {errors.newPassword && touched.newPassword && (
                      <div className="text-danger">{errors.newPassword}</div>
                    )}

                    {/* Confirm Password Input */}
                    <Input
                      formType="password"
                      formPlaceholder="CONFIRM PASSWORD"
                      formHandleChange={handleChange}
                      formHandleBlur={handleBlur}
                      formName="confirmPassword"
                      formValue={values.confirmPassword}
                      icon={Images.lock}
                      size="lg"
                      iconColor="white"
                    />
                    {errors.confirmPassword && touched.confirmPassword && (
                      <div className="text-danger">
                        {errors.confirmPassword}
                      </div>
                    )}

                    {/* Submit Button */}
                    <Button
                      type="submit"
                      className="btn-default w-100 mt-4"
                      disabled={loading}
                    >
                      {loading ? "Updating..." : "UPDATE PASSWORD"}
                    </Button>
                  </form>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default ChangePassword;
