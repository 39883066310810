import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, Col, Container, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { toast } from "react-toastify";
import { postRequestForm } from "../../../helper/api";
import Layout from "../../../layouts/Layout";
import { Images } from "../../../constants/Images";
import Input from "../../../constants/Input";

const SuperAdminResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const userId = location?.state?._id; // User ID passed from OTP verification

  if (!userId) {
    toast.error("Invalid access. Please restart the reset password process.");
    navigate("/superadmin/forget-password",{replace:true});
    return null;
  }

  // Validation Schema
  const passwordSchema = Yup.object().shape({
    password: Yup.string()
      .required("New password is required.")
      .min(6, "Password must be at least 6 characters."),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match.")
      .required("Confirm password is required."),
  });

  // Password Reset Handler
  const resetPasswordHandler = async (values) => {
    try {
      setLoading(true);

      const { result, error } = await postRequestForm(
        "/api/auth/v2/SuperAdmin/reset-password",
        "",
        {
          _id: userId, // Use the user ID passed in location.state
          password: values.password,
        }
      );

      if (result?.status === 200) {
        toast.success("Password has been updated successfully.");
        navigate("/superadmin/login",{replace:true}); // Redirect to login page
      } else {
        throw new Error(
          error?.response?.data?.message || "Password reset failed."
        );
      }
    } catch (err) {
      toast.error(err.message || "An error occurred.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <section
        id="newPassword"
        style={{
          backgroundImage: `url(${Images.background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={4} md={8} xs={12}>
              {/* Logo */}
              <div className="image-container text-center mb-5">
                <LazyLoadImage src={Images.logo} className="w-25" alt="Logo" />
              </div>

              {/* Password Reset Form */}
              <Formik
                initialValues={{ password: "", confirmPassword: "" }}
                validationSchema={passwordSchema}
                onSubmit={resetPasswordHandler}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="text-start">
                      <h1 className="newPassword">RESET PASSWORD</h1>
                      <p className="text mb-3">
                        Enter and confirm your new password to reset your
                        account.
                      </p>
                    </div>

                    {/* New Password Input */}
                    <Input
                      formType="password"
                      formPlaceholder="NEW PASSWORD"
                      formHandleChange={handleChange}
                      formHandleBlur={handleBlur}
                      formName="password"
                      formValue={values.password}
                      icon={Images.lock}
                      size="lg"
                      iconColor="white"
                    />
                    {errors.password && touched.password && (
                      <div className="text-danger">{errors.password}</div>
                    )}

                    {/* Confirm Password Input */}
                    <Input
                      formType="password"
                      formPlaceholder="CONFIRM PASSWORD"
                      formHandleChange={handleChange}
                      formHandleBlur={handleBlur}
                      formName="confirmPassword"
                      formValue={values.confirmPassword}
                      icon={Images.lock}
                      size="lg"
                      iconColor="white"
                    />
                    {errors.confirmPassword && touched.confirmPassword && (
                      <div className="text-danger">
                        {errors.confirmPassword}
                      </div>
                    )}

                    {/* Submit Button */}
                    <Button
                      type="submit"
                      className="btn-default w-100 mt-4"
                      disabled={loading}
                    >
                      {loading ? (
                        <span className="spinner-border spinner-border-sm"></span>
                      ) : (
                        "RESET PASSWORD"
                      )}
                    </Button>
                  </form>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default SuperAdminResetPassword;
