// components/ForbiddenPage.js
import React from "react";

const Forbidden = () => {
  return (
    <div className="forbidden-page">
      <h1 className="text-center">Error 403 | Forbidden</h1>
      <p className="text-center">
        You do not have access to view this page. Please contact the
        administrator if you believe this is an error.
      </p>
    </div>
  );
};

export default Forbidden;
